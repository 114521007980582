import React from 'react'
import styled from 'styled-components'

import Nav from '../components/nav'
import Footer from '../components/footer'
import SEO from '../components/seo'
import useHotjarScript from '../utils/hooks/useHotjarScript'

const PrivacyPolicy = () => {
  useHotjarScript()
  return (
    <>
      <Nav theme="light" />
      <SEO
        title="Privacy Policy - Pulse"
        description="Master asynchronous communication to increase your team communication bandwidth, stay more connected and aligned by communicating more openly and asynchronously."
      />
      <Container>
        <div className="article-body">
          <h1>
            <span>1. Introduction</span>
          </h1>
          <p>
            <span>
              Welcome to Sametab, Inc. (“Pulse”, "we", "us" and "our"). Pulse
              provides software and services that help companies communicate
              asynchronously more effectively and stay more aligned at
              scale.&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              The “Websites” means Pulse’s websites (including without
              limitation pulseasync.com, pulse.so, and any successor URLS,
              mobile or localized versions and related domains and subdomains),
              and the “Services” means Pulse’s products, applications and
              services, in each case in whatever format they may be offered now
              or in the future. As you use the Services, we collect and process
              information from and about you in order to provide you with access
              to the Services, enhance your experience while using the Service,
              and communicate with you.
            </span>
          </p>
          <p>&nbsp;</p>
          <h1>
            <span>2. Applicability of this Privacy Policy</span>
          </h1>
          <p>
            <span>
              This policy (the “Privacy Policy”) applies to your use of the
              Services and other interactions (e.g., customer service inquiries)
              you may have with Pulse.&nbsp; If you do not agree with this
              Privacy Policy, do not access or use our Services.
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              A separate agreement (the “Customer Agreement”) may be in place
              between your organization and Pulse that governs delivery, access
              and use of the Service by users who are authorized by your
              organization to use its instance of the Service (such
              Customer-specific instance of the Service is referred to herein as
              the “Customer Workspace”).&nbsp; The entity who enters into the
              Customer Agreement with Pulse controls the Customer Workspace used
              by its authorized users and may set certain rules related to your
              utilization of the Service, including limitations regarding which
              third party applications you can connect to via the Service.&nbsp;
              If you have questions about your organization’s specific Service
              settings and practices you should contact them
              directly.&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <h1>
            <span>3. Personal Data We Collect</span>
          </h1>
          <h2>
            <span>Account Registration Data That You Provide To Us</span>
          </h2>
          <p>
            <span>
              When you register for a Pulse account you are required to provide
              certain personal information (the “Personal Data”), such as your
              name, and email address, and telephone number, as well as other
              information that you voluntarily choose to add to your account
              profile, like a profile photo. If you choose to register for an
              account utilizing your credentials from a third party service
              (such as Google or Microsoft), then your name and email address
              will be provided to us as permitted by your profile settings
              within that third party services.&nbsp; If you are registering for
              a paid account you will also be required to provide payment
              information, such as payment card details (collected by our
              payment service provider), and Single Sign On (SSO) SAML 2.0
              credentials.&nbsp; We will inform you, when requesting your
              Personal Data, if certain data must be provided or if it is
              optional. We will also inform you of the consequences should you
              not wish to provide this data. We also collect the information
              that you may choose to submit to our customer support team, for
              example regarding a question you have or a problem you are
              experiencing with our Services. This may include your contact
              information, a summary of the problem you are experiencing and any
              other documentation or information that would be helpful in
              resolving the issue (which, at your option, may include Personal
              Data).
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>Automatically Collected Data</span>
          </h2>
          <p>
            <span>
              When you interact with Pulse through the Services, we
              automatically collect information about you through cookies (small
              text files placed on your device), mobile device identifier and
              other technologies. Please read the “Cookies” section below to
              learn more about how we use cookies and other technologies. When
              you visit our Websites, our servers record information (“log
              data”), including information that your browser automatically
              sends whenever you visit the Website. This log data includes your
              Internet Protocol (“IP”) address (from which we understand the
              country you are connecting from at the time you visit the Site),
              browser type and settings, and the date and time of your
              request.&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>Data From Other Services You Link To Your Account</span>
          </h2>
          <p>
            <span>
              In order to perform tasks that are part of the Services we require
              you to integrate your third party email service with your Service
              account. Doing so provides us with access to your email account(s)
              and the content included therein. You authorize this access by
              agreeing to this Privacy Policy and providing any consent required
              by your email provider.&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              We receive information about you when you or your administrator
              integrate or link a third-party service with our Services (a
              “Third Party Service”). For example, if you create an account or
              log into our Services using your Google or Microsoft credentials,
              we receive your name and email address as permitted by your Google
              or Microsoft profile settings in order to authenticate you.&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              You or your administrator may also integrate other Third Party
              Services you use with and into our Services. For example, you may
              authorize our Services to access and display messages from within
              an instant messaging application, or to access and display files
              from a third-party document sharing service. The information we
              receive when you integrate our Services with a Third Party Service
              depends on the settings, permissions and privacy policy associated
              with that Third Party service. Please check the privacy settings
              and privacy policy of these Third Party Services to understand
              what data may be disclosed to and shared with us.
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>Data from Business Partners</span>
          </h2>
          <p>
            <span>
              If you purchase our Services through a reseller or other Pulse
              business partner (a “Business Partner”), that Business Partner may
              provide certain information to us, such as your name, email
              address, company information, and financial information related to
              the Services you purchase.
            </span>
          </p>
          <p>&nbsp;</p>
          <h1>
            <span>4. How We Use Your Personal Data.</span>
          </h1>
          <p>
            <span>We collect Personal Data for the following purposes:</span>
          </p>
          <h2>
            <span>
              To Provide The Services And Manage Your Access To And Use Of The
              Services
            </span>
          </h2>
          <p>
            <span>
              We use information about you to provide the Services to you,
              including to register you for the Services, process transactions
              with you, authenticate you when you log in, provide customer
              support, and operate and maintain the Services.
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>
              To Communicate With You About The Services And Provide You With
              Customer Support
            </span>
          </h2>
          <p>
            <span>
              We use your contact information to communicate with you about the
              Services, respond to your customer support questions and requests,
              and to provide you notices regarding the Services (including
              notices about your account, product update announcements, security
              alerts, administrative notifications and the like).&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              In connection with providing you with customer support, we may
              also use the information we automatically collect and information
              that you provide to us and consent for us to view and utilize as
              part of a support request so that we can respond to your request,
              analyze information related to your support request, and to repair
              or improve the Services.&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              We may also provide you with tailored communications that are
              based on your interactions with the Services. For example, certain
              actions you take may trigger a third-party app suggestion or we
              may communicate with you as you begin using the Services to assist
              you as you learn to be proficient in your use of the
              Services.&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              These communications are fundamental to the Services and in most
              cases, you cannot opt-out of them. If an opt-out is available, you
              will find information about how to opt-out in the communication
              itself or in your account settings.
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>For Research And Development to Improve our Service</span>
          </h2>
          <p>
            <span>
              So that we can continually improve the functionality of our
              Services, we utilize our collective learnings about the way users
              interact with and use the Services, as well as feedback provided
              directly to us, to troubleshoot issues, fix bugs, enhance
              functionality, and identify areas for integration and improvement
              of the Services.
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>To Market And Promote Our Services</span>
          </h2>
          <p>
            <span>
              We use your contact information and information about how you use
              the Services to communicate directly with you, including by
              sending you newsletters, promotions, or information about current
              and future products and services. You may opt-out of receiving
              such communications at any time by (i) clicking the unsubscribe
              link included in all the emails you receive or (ii) contacting us
              as indicated in Section 11 below (“Contact”).&nbsp;&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>For Safety And Security</span>
          </h2>
          <p>
            <span>
              We use information about you and the way in which you use the
              Services to verify and authenticate accounts and activity, monitor
              for suspicious or fraudulent activity, and identify violations of
              our Services policies.
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>
              To Protect Our Legitimate Business Interests And Legal Rights
            </span>
          </h2>
          <p>
            <span>
              Where required by law, or where we think it is necessary to
              protect our legal rights and interests or the legal rights and
              interests of others, we use information about you in connection
              with legal claims, compliance and regulatory functions. If you ask
              us to delete your data or to be removed from our marketing lists
              and we are required to fulfill your request, we will keep basic
              data to identify you and prevent further unwanted processing. As
              used above, “legitimate interests” means our interests in
              conducting our business and managing and delivering the best
              Services to you. We will not use your Personal Data for activities
              where the impact on you overrides our interests unless we have
              your consent or those activities are otherwise required or
              permitted by law.
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>With Your Consent</span>
          </h2>
          <p>
            <span>
              We use Personal Data about you where you have given us consent to
              do so for a specific purpose not listed above. For example, with
              your permission, we may publish testimonials or featured customer
              stories on our Websites to promote the Services, including names
              and profile photos of representatives of our customers alongside
              the testimonial. While providing our Services, we may collect on
              behalf of our customers' information related to our customers’
              employees, business partners, and other individuals. Our use of
              such information on behalf of our customers is governed by our
              Customer Agreement with that customer and the customer’s own
              privacy policies. We are not responsible for the privacy policies
              or privacy practices of customers or other third parties.
            </span>
          </p>
          <p>&nbsp;</p>
          <h1>
            <span>5 - How We Share Your Personal Data</span>
          </h1>
          <p>
            <span>
              In order to provide you with the Service and operate our business
              effectively, we may need to share your Personal Data with certain
              third parties and service providers.&nbsp; This section explains
              when and why we share your information.&nbsp;&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>
              Sharing With Third-Party Service Providers for Business
              Purposes&nbsp;
            </span>
          </h2>
          <p>
            <span>
              We may share your Personal Data with third-party service providers
              for business purposes (as we have done for the preceding 12
              months). This Personal Data may include personal identifiers (such
              as your name and email address), and internet or other electronic
              network activity information (such as your IP address, type of
              browser, version of operating system, carrier and/or manufacturer,
              device identifiers, and mobile advertising identifiers), and
              location data.&nbsp; We disclose these categories of Personal Data
              to service providers who assist us in fulfilling the following
              business purposes:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Maintaining and delivering the Service (including hosting,
                back-up and other IT service providers, billing and payment
                processing services)
              </span>
            </li>
            <li>Providing you with customer service</li>
            <li>Detecting and preventing fraud</li>
            <li>
              Analyzing and improving the Service (including via website
              analytics and data science service providers)
            </li>
          </ul>
          <p>
            <span>
              Providing marketing and advertising services for Pulse (including
              digital advertising services, CRM services, and marketing and
              sales software solutions). Our third party advertising partners
              also use cookies and similar technologies to collect information
              about members for advertising purposes as described below and in
              our [Cookie Policy].
            </span>
          </p>
          <p>
            <span>
              These third-party service providers are provided only with that
              Personal Data that is necessary to perform the services we have
              hired them to provide.
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>For Legal Purposes to Protect Pulse and the Service</span>
          </h2>
          <p>
            <span>
              We may disclose your Personal Data if required to do so by law or
              if we have a good faith belief that such action is necessary to
              (i) comply with a legal obligation or lawful requests by public
              authorities, including to meet national security or law
              enforcement requirements, (ii) protect and defend the rights or
              property of Pulse, (iii) act in urgent circumstances to protect
              the personal safety of users of the Services or the public, or
              (iv) protect against legal liability.&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>In Connection With a Sale or Merger</span>
          </h2>
          <p>
            <span>
              As we develop our business, we might sell or buy businesses or
              assets. In the event of a corporate sale, merger, reorganization,
              dissolution or similar event, Personal Data may be part of the
              transferred assets.
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>With Your Consent</span>
          </h2>
          <p>
            <span>
              We may share Personal Data about you with third parties when you
              give us consent to do so. For example, with your permission we may
              publish and display testimonials or featured customer stories on
              our Websites, including names and profile photo of representatives
              of our customers alongside the testimonial.
            </span>
          </p>
          <p>&nbsp;</p>
          <h1>
            <span>6 – Data Retention</span>
          </h1>
          <p>
            <span>
              Pulse generally retains your Personal Data for a period of time
              consistent with the original purpose of collection. For instance,
              we may retain your Personal Data during the time you have an
              account to use our Websites or Services, or as agreed in our
              subscription agreements, and for a reasonable period of time
              thereafter. We also may retain your Personal Data as necessary to
              comply with our legal obligations, to establish proof of a right
              or a contract, resolve disputes, enforce our agreements, in
              accordance with the law.
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              Your credit card information is stored no longer than the time
              necessary to allow the fulfillment of the transaction, except in
              the case of a subscription, to facilitate the payment of regular
              customers. In that case, credit card information is stored for the
              whole duration of your subscription and at least until the date at
              which you carry out your last transaction. Such storage is
              implemented by our secured payment service provider Stripe.
            </span>
          </p>
          <p>&nbsp;</p>
          <h1>
            <span>7 - Privacy Shield</span>
          </h1>
          <p>
            <span>
              Sametab, Inc. complies with the EU-U.S. and Swiss-U.S. Privacy
              Shield Frameworks as set forth by the U.S. Department of Commerce
              regarding the collection, use, and retention of Personal Data
              transferred from the European Union and Switzerland to the United
              States. Sametab, Inc. has certified to the Department of Commerce
              that it adheres to the Privacy Shield Principles
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              JAMS is the US-based independent organization responsible for
              reviewing and resolving complaints about our Privacy Shield
              compliance – free of charge to you. We ask that you first submit
              any such complaints directly to us at{' '}
              <a href="privacy@pulse.so" target="_self">
                privacy@pulse.so
              </a>
              . If you are not satisfied with our response, please contact JAMS
              at www.jamsadr.com/eu-us-privacy-shield. In the event that your
              concern still is not addressed by JAMS, you may be entitled to a
              binding arbitration under Privacy Shield and its principles.
              Sametab, Inc. is subject to the investigatory and enforcement
              powers of the Federal Trade Commission (FTC) with respect to its
              compliance with the provisions of the EU-US and Swiss-US Privacy
              Shield. Within the scope of our authorization to do so, and in
              accordance with our commitments under the Privacy Shield, Sametab,
              Inc. will provide individuals access to Personal Data about them.
              Sametab, Inc. also will take reasonable steps to enable
              individuals to correct, amend, or delete Personal Data that is
              demonstrated to be inaccurate.
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              Sametab, Inc. is responsible for the processing of Personal Data
              it receives, under the Privacy Shield Framework, and subsequently
              transfers to service providers. Sametab, Inc. complies with the
              Privacy Shield Principles for all onward transfers of Personal
              Data from the EU and Switzerland, including the onward transfer
              liability provisions.&nbsp; Once the United Kingdom is no longer a
              Member State of the European Union, we will comply with the
              EU-U.S. Privacy Shield regarding the collection, use, and
              retention of Personal Data transferred from the United Kingdom to
              the U.S. in reliance on the EU-U.S. Privacy Shield Frameworks, or
              any successor framework between the U.S. and the United Kingdom.
            </span>
          </p>
          <p>&nbsp;</p>
          <h1>
            <span>8 - European Union (EU) Individuals</span>
          </h1>
          <h2>
            <span>Scope</span>
          </h2>
          <p>
            <span>
              This section applies if you are an individual in the EU (for these
              purposes, reference to the EU also includes the European Economic
              Area countries of Iceland, Liechtenstein and Norway and, to the
              extent applicable, Switzerland).
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>Data Controller</span>
          </h2>
          <p>
            <span>
              Sametab,Inc. is the data controller for processing of your
              Personal Data. We act as a data processor (or service provider) in
              relation to the Services we provide to our Customers.&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>Your Rights</span>
          </h2>
          <p>
            <span>
              Subject to EU data protection law, you have the following rights
              in relation to your Personal Data:
            </span>
          </p>
          <ul>
            <li>
              <span>
                <strong>Right Of Access</strong>: If you ask us, we will confirm
                whether we are processing your Personal Data and, if so, provide
                you with a copy of that Personal Data along with certain other
                details. If you require additional copies, we may need to charge
                a reasonable fee.
              </span>
            </li>
            <li>
              <span>
                <strong>Right To Rectification</strong>: If your Personal Data
                is inaccurate or incomplete, you are entitled to ask that we
                correct or complete it. If we shared your Personal Data with
                others, we will tell them about the correction where possible.
                If you ask us, and where possible and lawful to do so, we will
                also tell you with whom we shared your Personal Data so you can
                contact them directly.
              </span>
            </li>
            <li>
              <span>
                <strong>Right To Erasure</strong>: You may ask us to delete or
                remove your Personal Data, such as where you withdraw your
                consent. If we shared your data with others, we will alert them
                to the need for erasure where possible. If you ask us, and where
                possible and lawful to do so, we will also tell you with whom we
                shared your Personal Data with so you can contact them directly.
              </span>
            </li>
            <li>
              <span>
                <strong>Right To Restrict Processing</strong>: You may ask us to
                restrict or ‘block’ the processing of your Personal Data in
                certain circumstances, such as where you contest the accuracy of
                the data or object to us processing it (please read below for
                information on your right to object). We will tell you before we
                lift any restriction on processing. If we shared your Personal
                Data with others, we will tell them about the restriction where
                possible. If you ask us, and where possible and lawful to do so,
                we will also tell you with whom we shared your Personal Data so
                you can contact them directly.
              </span>
            </li>
            <li>
              <span>
                <strong>Right To Data Portability</strong>: You have the right
                to obtain your Personal Data from us that you consented to give
                us or that was provided to us as necessary in connection with
                our contract with you where we process that Personal Data in an
                automated way. We will give you your Personal Data in a
                structured, commonly used and machine-readable format. You may
                reuse it elsewhere.
              </span>
            </li>
            <li>
              <span>
                <strong>Right To Object</strong>: You may ask us at any time to
                stop processing your Personal Data, and we will do so: (1){' '}
              </span>
              If we are relying on a legitimate interest to process your
              Personal Data -- unless we demonstrate compelling legitimate
              grounds for the processing; or (2) If we are processing your
              Personal Data for direct marketing (you can always object using
              the unsubscribe link in our marketing communications).
            </li>
            <li>
              <strong>Right To Withdraw Consent:</strong> If you have consented
              to our use of your Personal Data for a specific purpose, you have
              the right to change your mind at any time, but this will not
              affect any processing of your data that has already taken place.
            </li>
            <li>
              <strong>
                Right To Make A Complaint With The Data Protection Authority
              </strong>
              : If you have a concern about our privacy practices, including the
              way we handled your Personal Data, you can report it to the data
              protection authority that is authorized to hear those concerns.
            </li>
          </ul>
          <p>
            <span>
              Please see the&nbsp; “Contact”&nbsp; section below for information
              on how to exercise your rights.
            </span>
          </p>
          <p>&nbsp;</p>
          <h1>
            <span>9- California Residents</span>
          </h1>
          <h2>
            <span>Scope</span>
          </h2>
          <p>
            <span>
              This section applies only if you are a resident of California and
              describes the rights granted to you pursuant to the California
              Consumer Privacy Act of 2018 (“<strong>CCPA</strong>”). For
              purposes of this section, “<strong>Personal Information</strong>”
              has the meaning given in the CCPA, but does not include
              information exempted from the scope of the CCPA.&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              Your California Rights.&nbsp; These rights are not absolute, are
              subject to exceptions, and we may be required or permitted by law
              to decline your request.&nbsp;
            </span>
          </p>
          <ul>
            <li>
              <span>
                Right to Know: You can request the following information about
                how we have collected and used your Personal Information during
                the past 12 months, including: (1){' '}
              </span>
              The categories of personal information we have collected about
              you; (2) The categories of sources from which we collected the
              personal information; (3) The business and commercial purpose for
              collecting and/or selling the personal information; (4) The
              categories of third parties with whom we have shared your personal
              information; (5) Whether we have disclosed your personal
              information for a business purpose and, if so, the categories of
              personal information received by each category of third party
              recipient.
            </li>
            <li>
              <span>
                <strong>Right of Access</strong>: You can request a copy of the
                personal information that we have collected about you during the
                past 12 months.
              </span>
            </li>
            <li>
              <strong>Right to Delete</strong>
              <span>
                : You can ask us to delete the personal information we have
                collected from you. We may be required or permitted by law to
                deny your request.&nbsp;{' '}
              </span>
            </li>
            <li>
              <strong>Opt-out of Sales</strong>: If we sell your personal
              information, you can opt-out of such sales in the future. In
              addition, if you direct us not to sell your personal information,
              we will consider it a request pursuant to California’s “Shine the
              Light” law to stop sharing your personal information covered by
              that law with third parties for their direct marketing purposes
            </li>
            <li>
              <strong>Nondiscrimination</strong>: You are entitled to exercise
              the rights described above free from discrimination. This means
              that we will not penalize you for exercising your rights by taking
              actions such as denying you services; increasing the price/rate of
              services; decreasing service quality; or suggesting that we may
              penalize you as described above for exercising your rights.
            </li>
          </ul>
          <h2>
            <span>How to Exercise Your California Privacy Rights</span>
          </h2>
          <p>
            <span>
              You may exercise your California privacy rights described above as
              follows:
            </span>
          </p>
          <ul>
            <li>
              <span>
                <strong>Right to information, access and deletion</strong>: You
                can request to exercise your information, access and deletion
                rights by contacting us using the information shown in Section
                12 below.
              </span>
            </li>
            <li>
              <strong>
                Right to opt-out of the “sale” of your Personal Information.
              </strong>{' '}
              We do not sell your Personal Information in the conventional sense
              (i.e., for money).
            </li>
          </ul>
          <p>
            <span>
              However, California law broadly defines “sale” in a way that may
              include using advertising services to deliver targeted advertising
              to you. As such, our use of these services may constitute a “sale”
              (as defined by California law). You have the right to direct us
              not to “sell” your personal information by visiting the following
              web page: Do Not Sell My Info.
            </span>
          </p>
          <p>
            <span>
              We will need to confirm your identity and California residency to
              process your requests to exercise your information, access or
              deletion rights.&nbsp; We cannot process your request if you do
              not provide us with sufficient detail to allow us to understand
              and respond to it.
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>
              California Required Information Regarding Personal Information We
              Collect and Share&nbsp;
            </span>
          </h2>
          <p>
            <span>
              The chart below summarizes how we collect, use, and share Personal
              Information by reference to the categories specified in the CCPA,
              and describes our practices during the 12 months preceding the
              effective date of this Privacy Policy.
            </span>
          </p>
          <p>
            <span>Identifiers</span>
          </p>
          <ul>
            <li>
              <span>
                <strong>Personal Information We Collect</strong>: Contact
                information such as your real name, email address, Internet
                Protocol address, online identifier, or unique personal
                identifier.
              </span>
            </li>
            <li>
              <span>
                <strong>Source</strong>: You (either directly or through a Third
                Party Service), collected automatically by the Service, Business
                Partners, and service providers.&nbsp;
              </span>
            </li>
          </ul>
          <h2>
            <span>Commercial Information</span>
          </h2>
          <ul>
            <li>
              <span>
                <strong>Personal Information We Collect:</strong> Records of
                products or services purchased
              </span>
            </li>
            <li>
              <strong>Source</strong>: You (either directly or through a Third
              Party Service), collected automatically by the Service, business
              partners, and service providers.
            </li>
          </ul>
          <h2>
            <span>Financial Information</span>
          </h2>
          <ul>
            <li>
              <span>
                <strong>Personal Information We Collect</strong>: Payment
                information
              </span>
            </li>
            <li>
              <strong>Source</strong>: You (either directly or through a Third
              Party Service),&nbsp; Business Partners and service providers.
            </li>
          </ul>
          <h2>
            <span>Online Identifiers</span>
          </h2>
          <ul>
            <li>
              <span>
                <strong>Personal Information We Collect</strong>: Device data,
                identity data
              </span>
            </li>
            <li>
              <strong>Source</strong>: You, (either directly or through a Third
              Party Service) Collected automatically by the Service, and service
              providers.
            </li>
          </ul>
          <h2>
            <span>Internet or Network Information</span>
          </h2>
          <ul>
            <li>
              <span>
                <strong>Personal Information We Collect</strong>: Browsing
                history, search history, information on your interactions with
                our website, application, or advertisement.
              </span>
            </li>
            <li>
              <strong>Source</strong>: You, (either directly or through a Third
              Party Service), collected automatically by the Service, and
              service providers.
            </li>
          </ul>
          <h2>
            <span>Geolocation Data</span>
          </h2>
          <ul>
            <li>
              <span>
                <strong>Personal Information We Collect</strong>: Physical
                location data from your mobile device or computer.
              </span>
            </li>
            <li>
              <strong>Source</strong>: You, (either directly or through a Third
              Party Service), collected automatically by the Service, and
              service providers.
            </li>
          </ul>
          <h2>
            <span>Inferences drawn from other personal information</span>
          </h2>
          <ul>
            <li>
              <span>
                <strong>Personal Information We Collect</strong>: Profile
                reflecting your intended or potential use of our service,
                preferences, characteristics, trends, predispositions, attitude,
                abilities and aptitudes.
              </span>
            </li>
            <li>
              <span>
                <strong>Source</strong>: You, (either directly or through a
                Third Party Service), collected automatically by the Service,
                Business Partners and service providers.
              </span>
            </li>
          </ul>
          <h2>Professional or Employment Information</h2>
          <ul>
            <li>
              <strong>Personal Information We Collect</strong>: Current job
              information such as title and name of company you work for.
            </li>
            <li>
              <strong>Source</strong>: You, (either directly or through a Third
              Party Service), collected automatically by the Service, Business
              Partners and service providers
            </li>
          </ul>
          <h2>
            <span>Physical Description</span>
          </h2>
          <ul>
            <li>
              <span>
                <strong>Personal Information We Collect</strong>: Profile photo
              </span>
            </li>
          </ul>
          <p>
            <span>
              We describe the sources from which we collect this information in
              the section above titled “Personal Data We Collect.” We describe
              the business and commercial purposes for which we collect this
              information in the sections above titled “How We Use Your Personal
              Data” And “How We Share Your Personal Data.”
            </span>
          </p>
          <p>&nbsp;</p>
          <h1>
            <span>10 – Cookies and Similar Technologies</span>
          </h1>
          <p>
            <span>
              Cookies are small text files that are placed on your computer or
              mobile device when you visit a website, mobile app or use an
              online platform. Cookies and similar technologies&nbsp; are widely
              used by online service providers to facilitate and help to make
              the interaction between users and websites, mobile apps and online
              platforms faster and easier, as well as to provide reporting
              information. For more information about cookies and their impact
              on you and your browsing visit&nbsp;{' '}
            </span>
            <a href="http://www.aboutcookies.org">
              <span>www.aboutcookies.org</span>
            </a>
          </p>
          <p>
            <span>
              Cookies set by the website and/or mobile app and/or platform owner
              (in this case, Pulse) are called "first party cookies". Cookies
              set by parties other than the website and/or mobile app and/or
              platform owner are called "third party cookies". Third party
              cookies enable third party features or functionality to be
              provided on or through the website and/or mobile app and/or
              platform (e.g. like advertising, interactive content and
              analytics). The parties that set these third party cookies can
              recognize your computer or device both when it visits the website
              and/or mobile app and/or platform in question and also when it
              visits certain other websites and/or mobile apps and/or platforms.
              By choosing to use our Websites after having been notified of our
              use of cookies in the ways described in this Privacy Policy, and,
              in applicable jurisdictions, through notice and unambiguous
              acknowledgement of your consent, you agree to such use.
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>Why Do We Use Cookies?</span>
          </h2>
          <p>
            <span>
              We use first party and third party cookies for several reasons.
              Some cookies are required for technical reasons in order for our
              Websites and/or App and/or platform to operate, and we refer to
              these as "essential" or "strictly necessary" cookies. Other
              cookies also enable us to track and target the interests of our
              users to enhance the experience on our Websites and/or App and/or
              platform. Third parties serve cookies through our Websites and/or
              App and/or platform for analytics and to help us deliver ads,
              measure the performance of those ads, and make them more relevant
              to you. This is described in more detail below.
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>Our Websites Cookies</span>
          </h2>
          <p>
            <span>Essential website cookies:</span>
          </p>
          <ul>
            <li>
              <strong>Purpose: </strong>These cookies are strictly necessary to
              provide you with services available through our Websites and to
              use some of its features.
            </li>
            <li>
              <strong>Vendor: </strong>Sametab, Inc.
            </li>
            <li>
              <strong>How to refuse: </strong>Because these cookies are strictly
              necessary to deliver the Websites and the Services to you, you
              cannot refuse them.
            </li>
          </ul>
          <p>
            <span>Analytics and customization cookies:&nbsp;</span>
          </p>
          <ul>
            <li>
              <strong>Purpose: </strong>These cookies collect information that
              is used either in aggregate form to help us understand how our
              Websites are being used or how effective marketing campaigns are,
              or to help us customize our Websites for you.
            </li>
            <li>
              <strong>Vendor:</strong>
              <ul>
                <li>
                  <span>Google Analytics</span>
                </li>
                <li>
                  <span>Fullstory</span>
                </li>
                <li>
                  <span>Facebook Pixel&nbsp;</span>
                </li>
                <li>
                  <span>Google AdWords</span>
                </li>
              </ul>
            </li>
            <li>
              <span>
                <strong>How to refuse:</strong>{' '}
              </span>
              To refuse these cookies, please follow the instructions below
              under the heading "Managing cookies" Alternatively, please click
              on the relevant opt-out link below:
              <ul>
                <li>
                  <a href="https://tools.google.com/dlpage/gaoptout/">
                    <span>Google Analytics OPT OUT</span>
                  </a>
                </li>
                <li>
                  <span>
                    <a href="https://www.fullstory.com/optout/">
                      FullStory OPT OUT
                    </a>
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <h2>Managing Cookies</h2>
          <p>
            <span>
              Most internet browsers allow you to erase cookies from your
              computer hard drive, block all cookies (or just third-party
              cookies), or warn you before a cookie is stored on your device.
              Please note, if you choose to block all cookies, our Services will
              not function as intended and you will not be able to use or access
              many of the features of the Services we provide. If you have
              blocked all cookies and wish to make full use of the features and
              Services we offer, you will need to enable your cookies. You can
              do this in your browser. Rather than blocking all cookies, you can
              choose to only block third-party cookies which will still allow
              our website to function as intended.
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span>How We Respond to Do Not Track (DNT) Signals</span>
          </h2>
          <p>
            <span>
              Some internet browsers have incorporated “Do Not Track” features
              which, when enabled, causes your browser to send a do not track
              HTTP header file or “signal” to each site you visit. At present,
              the Services do not respond to this type of signal.
            </span>
          </p>
          <p>&nbsp;</p>
          <h1>
            <span>11 - Modifications</span>
          </h1>
          <p>
            <span>
              We reserve the right, at our sole discretion, to modify this
              Privacy Policy or any portion thereof. Any changes will be
              effective from the time of publication of the new privacy policy.
              If we believe that the changes are material, we will let you know
              by doing one (or more) of the following: (i) posting the changes
              on or through the Services, (ii) sending you an email or message
              about the changes. Your continued use of the Services after the
              changes have been implemented shall indicate your agreement with
              the terms of such revised privacy policy. Otherwise, and if the
              new privacy policy does not suit you, you must no longer use the
              Services.
            </span>
          </p>
          <p>&nbsp;</p>
          <h1>
            <span>12 - Contact</span>
          </h1>
          <p>
            <span>
              Have questions or concerns about Pulse and privacy? Contact us by
              email at&nbsp; <a href="privacy@pulse.so">privacy@pulse.so</a> or
              by mail at:
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <em>
              <span>Sametab, Inc.</span>
            </em>
          </p>
          <p>
            <em>
              <span>405, Howard Street</span>
            </em>
          </p>
          <p>
            <em>
              <span>San Francisco, CA 94105</span>
            </em>
          </p>
          <p>
            <em>
              <span>USA</span>
            </em>
          </p>
        </div>
      </Container>
      <Footer />
    </>
  )
}

const Container = styled.article`
  padding-top: calc(96px + 64px);
  padding-bottom: 5rem;
  width: 43.75rem;
  max-width: 92.5vw;
  margin: auto;

  a {
    color: black;
  }
  @media ${props => props.theme.device.tabletLToLower} {
    padding-top: calc(48px + 64px);
  }
`

export default PrivacyPolicy
